<template>
  <div class="w-100 px-5 bg-white">
    <div class="row justify-content-between">
      <h1 class="h4 my-4">FarePlay Client portal</h1>
      <div class="my-auto">
        <span class="pr-3"
          >Do you need
          <a href="#" @click="modalAssistanceIsVisible = true">assistance</a
          >?</span
        >
        <a v-if="dashboardURL" target="_blank" :href="dashboardURL" class="pr-3"
          ><font-awesome-icon fixed-width icon="chart-line" /> Dashboard</a
        >
        <img
          v-if="
            userProfile && userProfile.profile && userProfile.profile.company
          "
          class="companyLogo"
          :src="require(`@/assets/${userProfile.profile.company}_logo.png`)"
        />
      </div>
    </div>
  </div>
  <el-dialog
    v-model="modalAssistanceIsVisible"
    title="Need assistance?"
    width="30%"
  >
    <span
      >We are more than happy to help. Please send us an email at
      <a href="mailto:info@fareplay.aero">info@fareplay.aero</a>.</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="modalAssistanceIsVisible = false">Close</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopMenu",
  data() {
    return {
      modalAssistanceIsVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      userProfile: "auth/userProfile",
      dashboardURL: "dashboardURL",
    }),
  },
  mounted() {},
};
</script>

<style scoped>
.companyLogo {
  max-height: 50px;
  max-width: 125px;
}
</style>