<template>
  <div id="wrapper" class="d-flex">
    <Menu />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenu />
        <section class="slice py-5">
          <div class="container">
            <div class="d-flex justify-content-between">
              <h4>Frontpage articles</h4>
              <div>
                <el-button type="primary" @click.prevent="showModalNewArticle"
                  ><font-awesome-icon icon="plus" /> Add article</el-button
                >
              </div>
            </div>
            <el-table
              class="table table-striped"
              :data="articles"
              :default-sort="{ prop: 'publish_on', order: 'descending' }"
              table-layout="auto"
            >
              <el-table-column prop="title" label="Title" sortable />
              <!--<el-table-column prop="description" label="Description" sortable>
                <template #default="scope"
                  >test
                  <div
                    v-html="truncate(scope.row.description, 100, true)"
                  ></div>
                </template>
              </el-table-column>-->
              <el-table-column prop="publish_on" label="Published on" sortable>
                <template #default="scope">
                  {{ convertFirestoreTimestampToDate(scope.row.publish_on) }}
                </template></el-table-column
              >
              <el-table-column width="150">
                <template #default="scope">
                  <div class="text-right">
                    <a
                      class="ml-3 remove d-inline-block"
                      href="#"
                      @click.prevent="showModalRemoveArticle(scope.row)"
                      ><font-awesome-icon icon="trash" /> Remove</a
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </section>

        <ModalNewArticle
          :showModal="isVisibleModalNewArticle"
          @closeModal="hideModalNewArticle"
        />
        <ModalRemoveArticle
          :article="toRemoveArticle"
          :showModal="isVisibleModalRemoveArticle"
          @closeModal="hideModalRemoveArticle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import TopMenu from "@/components/TopMenu.vue";
import ModalNewArticle from "@/components/ModalNewArticle.vue";
import ModalRemoveArticle from "@/components/ModalRemoveArticle.vue";
import { mapGetters } from "vuex";
import { format } from "date-fns";

export default {
  name: "Articles",
  components: {
    Menu,
    TopMenu,
    ModalNewArticle,
    ModalRemoveArticle,
  },
  data() {
    return {
      isVisibleModalNewArticle: false,
      isVisibleModalRemoveArticle: false,
      toRemoveArticle: null,
    };
  },
  computed: {
    ...mapGetters({ articles: "articles/articles" }),
  },
  methods: {
    submitForm() {},
    truncate(str, n, useWordBoundary) {
      if (str.length <= n) {
        return str;
      }
      const subString = str.slice(0, n - 1); // the original check
      return (
        (useWordBoundary
          ? subString.slice(0, subString.lastIndexOf(" "))
          : subString) + "..."
      );
    },
    convertFirestoreTimestampToDate(timestamp, formatString = "EEE dd MMM yy") {
      if (!timestamp) return "-";
      const date = timestamp.toDate();
      return format(date, formatString);
    },
    showModalNewArticle() {
      this.isVisibleModalNewArticle = true;
    },
    hideModalNewArticle() {
      this.isVisibleModalNewArticle = false;
    },
    showModalRemoveArticle(article) {
      this.isVisibleModalRemoveArticle = true;
      this.toRemoveArticle = article;
    },
    hideModalRemoveArticle() {
      this.isVisibleModalRemoveArticle = false;
    },
  },
  watch: {},
};
</script>

<style scoped>
.remove {
  color: #f56c6c;
}
</style>
