<template>
  <div v-if="isLoading">
    <p>Loading...</p>
  </div>
  <div v-else-if="isError">
    <p>Error</p>
  </div>
  <div v-else>
    <p>Current epoch: {{ currentEpoch }}</p>
    <h1>Nodes</h1>
    <table class="table">
      <tr>
        <th>Node</th>
        <th>Active</th>
        <th>Votes</th>
        <th>Props</th>
        <th>Proofs</th>
      </tr>
      <tr v-for="node in nodesInfo" :key="node.id">
        <td>{{ node.id.toUpperCase() }}</td>
        <td>{{ node.inActiveSet }}</td>
        <td>{{ node.votes }}</td>
        <td>{{ node.props }}</td>
        <td>{{ node.proofs }}</td>
      </tr>
    </table>

    <!--<h1>Towers</h1>
    {{ getTowerInfo("a697ad83b045722167fd385c9414f66d") }}
    <table>
      <tr>
        <th>Node</th>
        <th>Proofs</th>
      </tr>
      <tr v-for="node in towersInfo" :key="node.id">
        <td>{{ node.id }}</td>
        <td>{{ node.proofs }}</td>
      </tr>
    </table>-->
  </div>
</template>

<script>
import { functions } from "@/firebase";
//const fetch = require("node-fetch");

const nodeList = [
  "0D785EB2412263BEB0DBBB6468F35A1A",
  "79784B5981CC5407BA3BF9F7526F3F54",
  //"1e0f82ed044ad1b1e927f58da129a630",
  "04d3dd09c797f5962bbd0afbc19e22b9",
  "E202AA9B2CFDB96627531AF17832F2CA",
  "A35AE41A5F1701FCB67F4E4CAD59D259",
];

/*const towerList = [
  "a697ad83b045722167fd385c9414f66d",
  "FF7A10BBFD579F92FDC6559B5FBF1BB1",
  "48DB443FD44685144C6119A4531EF4A1",
  "708bf8e5f6a874479920fedb330748dd",
  "f29a9787ac93cf1915d872f435ad082c",
  "47F855B0E05BBE8E320B4164BDA592D2",
];*/

export default {
  name: "0L",
  components: {},
  data() {
    return {
      isLoading: true,
      isError: false,
      nodesInfo: [],
      towersInfo: [],
      currentEpoch: null,
    };
  },
  computed: {},
  methods: {
    /*getTowerInfo() {
      const requestOptions = {
        method: "GET",
        mode: "no-cors",
      };
      fetch(
        "https://0l.interblockcha.in/address/47F855B0E05BBE8E320B4164BDA592D2",
        requestOptions
      ).then((response) => {
        console.log(response.text());
      });
    },*/
  },
  mounted() {
    const getVitals = functions.httpsCallable("get0LVitals");
    getVitals()
      .then((response) => {
        const vitals = JSON.parse(response.data.vitals);
        this.currentEpoch = vitals.chain_view.epoch;

        for (let node of nodeList) {
          const nodeKeyIndex = findArrayKey(
            node,
            vitals.chain_view.validators_stats.current.addr
          );

          if (nodeKeyIndex === -1) {
            this.nodesInfo.push({
              id: node,
              inActiveSet: false,
              props: 0,
              votes: 0,
              proofs: 0,
            });
          } else {
            this.nodesInfo.push({
              id: node,
              inActiveSet: true,
              props:
                vitals.chain_view.validators_stats.current.prop_count[
                  nodeKeyIndex
                ],
              votes:
                vitals.chain_view.validators_stats.current.vote_count[
                  nodeKeyIndex
                ],
              proofs:
                vitals.chain_view.validator_view[nodeKeyIndex]
                  .count_proofs_in_epoch,
            });
          }
        }

        this.isLoading = false;
      })
      .catch((error) => {
        this.isError = true;
        this.$message.error(
          "An error has occured while getting vitals: " + error
        );
        console.error(error);
      });
  },
};

function findArrayKey(search, array) {
  const findValue = (element) => element.toLowerCase() === search.toLowerCase();
  return array.findIndex(findValue);
}
</script>
