<template>
  <div id="wrapper" class="d-flex">
    <Menu />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenu />
        <section class="slice py-5">
          <div class="container">
            <div class="d-flex justify-content-between">
              <h3>Files</h3>
              <div class="my-auto">
                <span v-if="filesLastUpdate"
                  >Last refresh:
                  {{
                    formatISODateTime(filesLastUpdate, "EEE dd LLL HH:mm")
                  }}</span
                >
                <!--<a href="#" class="pl-3 my-auto" @click.prevent="refreshFiles"
                  ><font-awesome-icon
                    :class="{ 'fa-spin': refreshIconLoading }"
                    fixed-width
                    :icon="['fas', 'sync']"
                /></a>-->
              </div>
            </div>
            <div v-loading="refreshIconLoading">
              <div v-if="chunkedFiles && chunkedFiles.length > 0">
                <div
                  class="row mx-n2"
                  v-for="file in chunkedFiles[pageIndex]"
                  :key="file.id"
                >
                  <div class="card mb-3 hover-shadow-lg">
                    <div
                      class="
                        card-body
                        d-flex
                        align-items-center
                        flex-wrap flex-lg-nowrap
                        py-0
                      "
                    >
                      <div
                        class="
                          col-auto col-lg-1
                          d-flex
                          align-items-center
                          px-0
                          pt-3 pt-lg-0
                          zindex-100
                        "
                      >
                        <font-awesome-icon icon="file-csv" />
                      </div>
                      <div class="col-lg-2 col-8 pl-0 pl-md-2 pt-3 pt-lg-0">
                        <span class="h6 text-sm">{{ file.job }}</span>
                      </div>
                      <div
                        class="
                          col-12 col-lg-7
                          d-flex
                          align-items-center
                          position-static
                          pb-3
                          py-lg-3
                          px-0
                        "
                      >
                        <div class="col col-lg-11 position-static px-0">
                          <div
                            class="
                              d-flex
                              flex-wrap flex-lg-nowrap
                              align-items-center
                            "
                          >
                            <!--<div class="col-12 col-lg-auto px-0 position-static">
                    <a
                      href="#"
                      class="
                        stretched-link
                        h6
                        d-block
                        mb-0
                        lh-140
                        text-sm
                        font-weight-light
                      "
                      >Purpose 2.0 is now released</a
                    >
                  </div>-->
                            <div
                              class="
                                col-12 col-lg
                                pl-0
                                text-limit text-sm text-muted
                                d-none d-sm-block
                                pl-lg-2
                              "
                            >
                              {{ file.job_id }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          col col-lg-2
                          text-right
                          px-0
                          order-lg-4
                          pt-3 pt-lg-0
                        "
                      >
                        <span class="text-muted text-sm">{{
                          formatFirestoreDateTime(
                            file.created_at,
                            "dd MMM yy HH:ii"
                          )
                        }}</span>
                        <a
                          href="#"
                          target="_blank"
                          @click.prevent="downloadFile(file)"
                          class="pl-3"
                          ><font-awesome-icon icon="file-download"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3 mx-auto" align="center">
                  <el-pagination
                    background
                    :currentPage="pageIndex + 1"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="totalFilesInStorage"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
                <!--<div
                  v-if="chunkedFiles && chunkedFiles.length > 1"
                  align="center"
                  role="pagination"
                  class="el-pagination is-background py-3"
                >
                  <button
                    type="button"
                    class="btn-prev"
                    :disabled="paginationPreviousDisabled"
                    @click.prevent="paginate(event, pageIndex - 1)"
                  >
                    <i class="el-icon el-icon-arrow-left"></i>
                  </button>
                  <ul class="el-pager">
                    <li
                      class="number"
                      :class="{ active: pageIndex === index }"
                      :key="file.id"
                      v-for="(file, index) in chunkedFiles"
                      @click.prevent="paginate(event, index)"
                    >
                      {{ index + 1 }}
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn-next"
                    :disabled="paginationNextDisabled"
                    @click.prevent="paginate(event, pageIndex + 1)"
                  >
                    <i class="el-icon el-icon-arrow-right"></i>
                  </button>
                </div>-->
              </div>
              <div v-else>Sorry, no files are found in your storage.</div>
            </div>
          </div>
          <div align="center" class="text-muted mt-5">
            For more details about the specific fields, please have a look at
            the
            <a target="_blank" :href="basePath + '/files/datadictionary.pdf'">
              data dictionary</a
            >.
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatISODateTime, formatFirestoreDateTime } from "@/common";
import Menu from "@/components/Menu.vue";
import TopMenu from "@/components/TopMenu.vue";

export default {
  name: "Files",
  components: {
    Menu,
    TopMenu,
  },
  data() {
    return {
      refreshIconLoading: false,
      pageIndex: 0,
      pageSize: 8,
    };
  },
  computed: {
    ...mapGetters({
      files: "storage/files",
      filesLastUpdate: "storage/lastUpdate",
      minCreatedAt: "storage/minCreatedAt",
      maxCreatedAt: "storage/maxCreatedAt",
      totalFilesInStorage: "storage/totalFilesInStorage",
      userProfile: "auth/userProfile",
    }),
    basePath() {
      return window.location.origin;
    },
    chunkedFiles() {
      if (!this.files) return [];
      return sliceIntoChunks(this.files, this.pageSize);
    },
    paginationPreviousDisabled() {
      return this.pageIndex === 0;
    },
    paginationNextDisabled() {
      return this.pageIndex === this.chunkedFiles.length - 1;
    },
  },
  methods: {
    formatISODateTime(datetime, formatString = "dd MMM yy") {
      return formatISODateTime(datetime, formatString);
    },
    formatFirestoreDateTime(datetime, formatString = "dd MMM yy") {
      return formatFirestoreDateTime(datetime, formatString);
    },
    async downloadFile(file) {
      await this.$store.dispatch("storage/downloadFile", file);
    },
    handleCurrentChange(val) {
      this.pageIndex = parseInt(val - 1);
    },
    paginate(event, pageIndex) {
      this.pageIndex = parseInt(pageIndex);
      //window.scrollTo({ top: 0, behavior: "smooth" });
    },
    refreshFiles() {
      return true;
      // eslint-disable-next-line no-unreachable
      this.refreshIconLoading = true;
      this.$store.dispatch("storage/getFiles").then(
        () => {
          this.refreshIconLoading = false;
        },
        (error) => {
          console.error(error);
          this.refreshIconLoading = false;
        }
      );
    },
  },
};

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}
</script>

<style scoped>
.card {
  width: 100%;
}

.companyLogo {
  max-height: 50px;
  max-width: 125px;
}
</style>
