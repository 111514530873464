<template>
  <el-dialog
    center
    v-model="isVisibleModal"
    title="New article"
    width="70%"
    :before-close="closeModal"
    :close-on-click-modal="false"
  >
    <el-form ref="formNewArticle" :model="form" :rules="rules">
      <el-card>
        <el-form-item label="Title" prop="title">
          <el-input
            v-model="form.title"
            maxlength="50"
            show-word-limit
            placeholder="Provide a title for this article"
          ></el-input>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <Editor
            api-key="vl9uz4wps9eec3edkexl7xz1otw8ufbkym52tllax9zcuczk"
            :init="{
              plugins: 'lists link image table code help wordcount',
              width: '100%',
            }"
            v-model="form.description"
          />
          <!--<el-input
          v-model="form.description"
          :rows="5"
          type="textarea"
          placeholder="Provide a description for this article"
        ></el-input>-->
        </el-form-item>
        <el-form-item label="Publish on" prop="publish_on">
          <el-date-picker
            v-model="form.publish_on"
            type="date"
            format="ddd DD MMM YYYY"
            value-format="YYYY-MM-DD"
            placeholder="Choose a date to publish article"
            :disabled-date="disabledDates"
          >
          </el-date-picker>
        </el-form-item>
      </el-card>
      <el-card class="mt-3">
        <div class="row">
          <div class="col-lg-6">
            <el-form-item label="Thumbnail" prop="thumbnail">
              <el-button type="primary" @click="selectThumbnail"
                ><font-awesome-icon icon="camera" /> Choose thumbnail</el-button
              >
              <input
                type="file"
                ref="thumbnail"
                style="display: none"
                @change="changeThumbnail"
                accept="image/*"
              />
            </el-form-item>
            <p class="small">
              It is recommended to use an image with 494x337 dimensions
            </p>
          </div>
          <div class="col-lg-6">
            <el-form-item label="Thumbnail name" prop="thumbnailName">
              <el-input
                v-model="form.thumbnailName"
                placeholder="Provide a name for this thumbnail"
                ><template #append
                  >.{{ form.thumbnailExtension }}</template
                ></el-input
              >
            </el-form-item>
            <p class="small">
              The thumbnmail will be saved to server using this name
            </p>
          </div>
        </div>
      </el-card>
      <el-card class="mt-3">
        <div class="row">
          <div class="col-lg-6">
            <el-form-item label="Attachment (optional)" prop="attachment">
              <el-button type="primary" @click="selectAttachment"
                ><font-awesome-icon icon="file-pdf" /> Choose
                attachment</el-button
              >
              <input
                type="file"
                ref="attachment"
                style="display: none"
                @change="changeAttachment"
                accept=".pdf"
              />
            </el-form-item>
          </div>
          <div class="col-lg-6">
            <el-form-item label="Attachment name" prop="attachment_name">
              <el-input
                v-model="form.attachmentName"
                placeholder="Provide a name for this attachment"
                ><template #append
                  >.{{ form.attachmentExtension }}</template
                ></el-input
              >
            </el-form-item>
            <p class="small">
              The attachment will be saved to server using this name
            </p>
          </div>
        </div>
      </el-card>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button
          type="primary"
          @click.prevent="addArticle"
          :loading="modalIsLoading"
          >Create</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { ElMessage } from "element-plus";
import { isPast, isToday, format } from "date-fns";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  components: { Editor },
  data() {
    return {
      modalIsLoading: false,
      form: {
        title: "",
        description: "",
        publish_on: format(new Date(), "yyyy-MM-dd"),
        thumbnail: null,
        thumbnailName: null,
        thumbnailExtension: null,
        attachment: null,
        attachmentName: null,
        attachmentExtension: null,
      },
      rules: {
        title: [
          {
            required: true,
            message: "Please provide a title",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please provide a description",
            trigger: "blur",
          },
        ],
        publish_on: [
          {
            required: true,
            message: "Please provide a date to publish the article",
            trigger: "blur",
          },
        ],
        thumbnail: [
          {
            required: true,
            message: "Please provide a thumbnail to publish with the article",
            trigger: "blur",
          },
        ],
        thumbnailName: [
          {
            required: true,
            message: "Please provide a name for this thumbnail",
            trigger: "blur",
          },
        ],
      },
    };
  },
  emits: ["closeModal"],
  methods: {
    resetForm() {
      this.$refs.attachment.value = "";
      this.$refs.thumbnail.value = "";
      this.form.title = "";
      this.form.description = "";
      this.form.publish_on = format(new Date(), "yyyy-MM-dd");
      this.form.thumbnail = null;
      this.form.thumbnailName = null;
      this.form.thumbnailExtension = null;
      this.form.attachment = null;
      this.form.attachmentName = null;
      this.form.attachmentExtension = null;
      this.modalIsLoading = false;
    },
    selectThumbnail() {
      this.$refs.thumbnail.click();
    },
    changeThumbnail(event) {
      const name = event.target.files[0].name;
      const lastDot = name.lastIndexOf(".");

      this.form.thumbnail = event.target.files[0];
      this.form.thumbnailName = name.substring(0, lastDot);
      this.form.thumbnailExtension = name.substring(lastDot + 1);

      this.$refs["formNewArticle"].validate().then(() => {});
    },
    selectAttachment() {
      this.$refs.attachment.click();
    },
    changeAttachment(event) {
      const name = event.target.files[0].name;
      const lastDot = name.lastIndexOf(".");

      this.form.attachment = event.target.files[0];
      this.form.attachmentName = name.substring(0, lastDot);
      this.form.attachmentExtension = name.substring(lastDot + 1);
    },
    closeModal() {
      this.resetForm();
      this.isVisibleModal = false;
      this.$emit("closeModal");
    },
    disabledDates(date) {
      return false;
      // eslint-disable-next-line no-unreachable
      return isPast(date) && !isToday(date);
    },
    addArticle() {
      this.modalIsLoading = true;
      this.$refs["formNewArticle"].validate((valid) => {
        if (!valid) {
          this.modalIsLoading = false;
          return;
        }

        this.$store
          .dispatch("articles/addArticle", this.form)
          .then(() => {
            this.modalIsLoading = false;
            ElMessage({
              showClose: true,
              message: "Your article has been uploaded successfully",
              type: "success",
            });

            this.closeModal();
          })
          .catch((error) => {
            this.modalIsLoading = false;
            console.error(error);
            ElMessage({
              showClose: true,
              message: error,
              type: "error",
            });
          });
      });
    },
  },
  watch: {
    showModal: {
      handler() {
        this.isVisibleModal = this.showModal;
      },
      immediate: true,
    },
  },
};
</script>

<style>
.tox-tinymce-aux {
  z-index: 999999 !important;
}
</style>