<template>
  <el-dialog
    center
    v-model="isVisibleModal"
    title="Remove article"
    width="30%"
    :before-close="closeModal"
  >
    <div class="spinner-border" role="status" v-if="!article"></div>
    <p v-else>
      Confirm you want to remove the article
      <strong>{{ article.title }}</strong
      >?
    </p>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button
          type="danger"
          @click="removeArticle"
          :loading="modalIsLoading"
          >Remove</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    article: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      modalIsLoading: false,
    };
  },
  emits: ["closeModal"],
  methods: {
    closeModal() {
      this.isVisibleModal = false;
      this.$emit("closeModal");
    },
    removeArticle() {
      this.modalIsLoading = true;
      this.$store
        .dispatch("articles/removeArticle", this.article)
        .then(() => {
          this.modalIsLoading = false;
          this.closeModal();
        })
        .catch((error) => {
          console.error(error);
          this.modalIsLoading = false;
          ElMessage({
            showClose: true,
            message: error,
            type: "danger",
          });
        });
    },
  },
  watch: {
    showModal: {
      handler() {
        this.isVisibleModal = this.showModal;
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>