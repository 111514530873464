<template>
  <div id="wrapper" class="d-flex">
    <Menu />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenu />
        <section class="slice py-5">
          <div class="container">
            <h3>Home</h3>
            <p>
              This portal offers you a secure environment to
              <router-link :to="{ name: 'files' }"
                >retrieve the results</router-link
              >
              from your fare searches. You can also setup and manage your
              <router-link :to="{ name: 'adhoc' }">adhoc jobs</router-link>.
            </p>
            <h3 class="mt-5">Files</h3>
            <div v-loading="files === null">
              <div v-if="files && files.length > 0">
                <div
                  class="row mx-n2"
                  v-for="file in files.slice(0, 3)"
                  :key="file.id"
                >
                  <div class="card mb-3 hover-shadow-lg">
                    <div
                      class="
                        card-body
                        d-flex
                        align-items-center
                        flex-wrap flex-lg-nowrap
                        py-0
                      "
                    >
                      <div
                        class="
                          col-auto col-lg-1
                          d-flex
                          align-items-center
                          px-0
                          pt-3 pt-lg-0
                          zindex-100
                        "
                      >
                        <font-awesome-icon icon="file-csv" />
                      </div>
                      <div class="col-lg-2 col-8 pl-0 pl-md-2 pt-3 pt-lg-0">
                        <span class="h6 text-sm">{{ file.job }}</span>
                      </div>
                      <div
                        class="
                          col-12 col-lg-7
                          d-flex
                          align-items-center
                          position-static
                          pb-3
                          py-lg-3
                          px-0
                        "
                      >
                        <div class="col col-lg-11 position-static px-0">
                          <div
                            class="
                              d-flex
                              flex-wrap flex-lg-nowrap
                              align-items-center
                            "
                          >
                            <div
                              class="
                                col-12 col-lg
                                pl-0
                                text-limit text-sm text-muted
                                d-none d-sm-block
                                pl-lg-2
                              "
                            >
                              {{ file.job_id }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          col col-lg-2
                          text-right
                          px-0
                          order-lg-4
                          pt-3 pt-lg-0
                        "
                      >
                        <span class="text-muted text-sm">{{
                          formatFirestoreDateTime(file.created_at)
                        }}</span>
                        <a
                          href="#"
                          target="_blank"
                          @click.prevent="downloadFile(file)"
                          class="pl-3"
                          ><font-awesome-icon icon="file-download"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>Sorry, no files are found in your storage.</div>
            </div>

            <p>
              An overview of all your files can be found in the
              <router-link :to="{ name: 'files' }">Files</router-link> section.
            </p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import TopMenu from "@/components/TopMenu.vue";
import { mapGetters } from "vuex";
import { formatISODateTime, formatFirestoreDateTime } from "@/common";

export default {
  name: "Home",
  components: {
    Menu,
    TopMenu,
  },
  data() {
    return {
      init: false,
    };
  },
  computed: {
    ...mapGetters({
      files: "storage/files",
    }),
  },
  methods: {
    formatISODateTime(datetime, formatString = "dd MMM yy") {
      return formatISODateTime(datetime, formatString);
    },
    formatFirestoreDateTime(datetime, formatString = "dd MMM yy") {
      return formatFirestoreDateTime(datetime, formatString);
    },
    async downloadFile(file) {
      await this.$store.dispatch("storage/downloadFile", file);
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
}
</style>
