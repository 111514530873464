<template>
  <div id="leftMenu">
    <ul class="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
      <div class="flex-grow-1">
        <router-link
          class="sidebar-brand d-flex align-items-center justify-content-center"
          :to="{ name: 'home' }"
          ><img alt="Logo" src="@/assets/fareplay_logo.png"
        /></router-link>

        <li class="nav-item mt-3">
          <router-link class="nav-link" :to="{ name: 'home' }"
            ><font-awesome-icon fixed-width icon="home" /> Home</router-link
          >
        </li>
        <li class="nav-item mt-3">
          <router-link class="nav-link" :to="{ name: 'files' }"
            ><font-awesome-icon fixed-width :icon="['fas', 'file-csv']" />
            Files</router-link
          >
        </li>
        <!-- v-if="
            userProfile &&
            userProfile.profile &&
            userProfile.profile.company &&
            userProfile.profile.company === 'internal'
          " -->
        <li class="nav-item mt-3">
          <router-link class="nav-link" :to="{ name: 'adhoc' }"
            ><font-awesome-icon fixed-width icon="equals" /> Adhoc</router-link
          >
        </li>
        <div v-if="isAdmin">
          <hr class="sidebar-divider my-3" />
          <li class="nav-item mt-3">
            <router-link class="nav-link" :to="{ name: 'articles' }"
              ><font-awesome-icon fixed-width icon="newspaper" />
              Articles</router-link
            >
          </li>
        </div>
        <!--<li class="nav-item mt-3">
        <router-link class="nav-link" :to="{ name: 'generator' }"
          ><font-awesome-icon fixed-width icon="list" /> Generator</router-link
        >
        </li>-->
        <hr class="sidebar-divider my-3" />
        <li class="nav-item mt-3">
          <router-link class="nav-link" :to="{ name: 'account' }"
            ><font-awesome-icon fixed-width icon="cog" /> Account</router-link
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logout" href="#"
            ><font-awesome-icon fixed-width icon="sign-out-alt" /> Logout</a
          >
        </li>
      </div>
      <div class="text-center text-muted small">Version {{ version }}</div>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { version } from "@/../package";

export default {
  name: "Menu",
  data() {
    return {
      version: version,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      userProfile: "auth/userProfile",
      isAdmin: "auth/isAdmin",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions("auth", ["logout"]),
  },
};
</script>

<style scoped>
img {
  vertical-align: middle;
  width: 125px;
}

#leftMenu {
  position: relative;
}

#leftMenu > ul {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
</style>