<template>
  <div id="wrapper" class="d-flex">
    <Menu />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <div class="w-100 px-5 bg-white">
          <div class="row justify-content-between">
            <h1 class="h4 my-4">Generator</h1>
            <div>
              <a target="_blank" href="#" class="pr-3"
                ><font-awesome-icon fixed-width icon="chart-line" /> To
                Dashboard</a
              >
              <img
                v-if="
                  userProfile &&
                  userProfile.profile &&
                  userProfile.profile.company
                "
                class="companyLogo"
                :src="
                  require(`@/assets/${userProfile.profile.company}_logo.png`)
                "
              />
            </div>
          </div>
        </div>
        <section class="slice py-5">
          <div class="container">
            <h1>Generator</h1>
            <p>Create a JSON batch request</p>
            <el-form :model="form">
              <div v-for="row in form" :key="row.id">
                <div class="row">
                  <div class="col-12">
                    <el-form-item label="Job name">
                      <el-input
                        v-model="row.job"
                        placeholder="Job name"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <el-form-item label="Departure IATA">
                      <el-input
                        v-model="row.departureIATA"
                        placeholder="Departure IATA"
                        maxlength="3"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-6">
                    <el-form-item label="Arrival IATA">
                      <el-input
                        v-model="row.arrivalIATA"
                        placeholder="Arrival IATA"
                        maxlength="3"
                      ></el-input
                    ></el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 pb-3">
                    <el-switch
                      v-model="row.isReturn"
                      active-text="Is a return"
                      inactive-text="Is a single"
                    >
                    </el-switch>
                  </div>
                  <div class="col-9">
                    <el-form-item label="Days to return" v-if="row.isReturn">
                      <el-input
                        v-model="row.daysToReturn"
                        placeholder="Days to return"
                        maxlength="3"
                      ></el-input
                    ></el-form-item>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <el-switch
                      v-model="row.carryOnBag"
                      active-text="Carry on bag"
                      inactive-text="No carry on bag"
                    >
                    </el-switch>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <label class="el-form-item__label float-none"
                      >Start date</label
                    >
                    <div class="d-block">
                      <el-date-picker
                        v-model="row.startDate"
                        type="date"
                        placeholder="Start date"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                  <div class="col-5">
                    <el-form-item label="Days to go">
                      <el-input
                        v-model="row.daysToGo"
                        placeholder="Days to go"
                        maxlength="3"
                      ></el-input
                    ></el-form-item>
                  </div>
                  <div class="col-3">
                    <label class="el-form-item__label float-none"
                      >Additional days</label
                    >
                    <div>
                      <el-checkbox v-model="row.additionalDays[45]"
                        >45 days</el-checkbox
                      >
                    </div>
                    <div>
                      <el-checkbox v-model="row.additionalDays[60]"
                        >60 days</el-checkbox
                      >
                    </div>
                    <div>
                      <el-checkbox v-model="row.additionalDays[90]"
                        >90 days</el-checkbox
                      >
                    </div>
                    <div>
                      <el-checkbox v-model="row.additionalDays[120]"
                        >120 days</el-checkbox
                      >
                    </div>
                    <div>
                      <el-checkbox v-model="row.additionalDays[150]"
                        >150 days</el-checkbox
                      >
                    </div>
                    <div>
                      <el-checkbox v-model="row.additionalDays[180]"
                        >180 days</el-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
            <el-button type="primary" disabled @click="onSubmit"
              >Generate</el-button
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import { mapGetters } from "vuex";
import { format, add } from "date-fns";

export default {
  name: "Generator",
  components: {
    Menu,
  },
  data() {
    return {
      form: [
        {
          id: +new Date(),
          job: "",
          departureIATA: "",
          arrivalIATA: "",
          startDate: format(add(new Date(), { days: 1 }), "yyyy-MM-dd"),
          isReturn: true,
          daysToReturn: 7,
          carryOnBag: false,
          daysToGo: 30,
          additionalDays: {
            45: false,
            60: false,
            90: true,
            120: false,
            150: true,
            180: false,
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ userProfile: "auth/userProfile" }),
  },
  methods: {
    onSubmit() {
      console.log(this.form);
    },
  },
};
</script>

<style scoped>
.companyLogo {
  max-height: 50px;
  max-width: 125px;
}
</style>
